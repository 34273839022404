import { useEffect, useState } from "react"

import { useFetchHomeGroups } from "src/data/homeGroups/queries/homeGroupQueries"
import {
  THomeGroupFilter,
  THomeGroupType,
  TMaybeHomeGroup,
} from "src/data/homeGroups/types/homeGroupTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { ComboboxStateful } from "src/ui/Combobox/ComboboxStateful"
import { purgeEmpty } from "src/utils/genericUtil"

export interface IHomeGroupDropdownProps {
  onSelect: (h: TMaybeHomeGroup) => void
  value: TMaybeHomeGroup
  required?: boolean
  initialHomeGroupId?: string
  type?: THomeGroupType
  baseFilter?: Omit<THomeGroupFilter, "type">
}

export function HomeGroupsDropdown({
  value,
  onSelect,
  required,
  initialHomeGroupId,
  type = "area",
  baseFilter,
}: IHomeGroupDropdownProps) {
  const { t, langKeys } = useTranslate()
  const [search, setSearch] = useState("")
  const { orgId } = useOrganization()
  const homegroupsQuery = useFetchHomeGroups({
    orgId,
    filter: purgeEmpty({ ...baseFilter, name: search || undefined, type }),
  })

  // If an initial id is provided, set that as our initial value as soon as the
  // data has been loaded.
  useEffect(() => {
    if (
      initialHomeGroupId &&
      !value?.name &&
      homegroupsQuery.data?.homegroups
    ) {
      const initialHomegroup = homegroupsQuery.data?.homegroups.find(
        (hg) => hg.id === initialHomeGroupId
      )
      onSelect(initialHomegroup ?? null)
    }
  }, [homegroupsQuery.data?.homegroups, initialHomeGroupId, onSelect, value])

  return (
    <ComboboxStateful
      options={(homegroupsQuery.data?.homegroups ?? []).map((homegroup) => ({
        label: homegroup.name,
        value: homegroup.id,
      }))}
      selectedValue={value?.id || ""}
      onSearch={setSearch}
      onChange={(value) => {
        const selectedHomeGroup = homegroupsQuery.data?.homegroups.find(
          (homegroup) => homegroup.id === value
        )

        onSelect(selectedHomeGroup ?? null)
      }}
      label={
        type === "area"
          ? t(langKeys.organizations_home_group)
          : t(langKeys.building)
      }
      required={required}
    />
  )
}
